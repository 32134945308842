.itemContainer {
  text-align: left;
}

.grid {
  display: grid;
  @media (--theme-mediaQuery-desktop) {
    gap: 2%;
    grid-template-columns: repeat(2, 49%);
    height: 100%;
  }
  img {
    border-radius: var(--theme-borderRadiusSmall);
  }
  em {
    font-size: var(--theme-font-size-legalDesktop);
    font-style: normal;
  }
}

.richText {
  font-size: var(--theme-font-size-bodyMobile);
  @media (--theme-mediaQuery-desktop) {
    font-size: var(--theme-font-size-bodyDesktop);
  }
}
