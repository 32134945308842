.heading {
  font-size: var(--theme-font-size-h1SMobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-h1SDesktop);
  }
}

.container {
  text-align: left;
  border-left: var(--theme-spacing-4) solid var(--theme-color-primary);
  padding-left: var(--theme-spacing-16);
  p {
    margin: 0;
    font-weight: bold;
    font-size: var(--theme-font-size-titleMobile);
    @media (--theme-mediaQuery-tablet) {
      font-size: var(--theme-font-size-titleDesktop);
    }
  }
}
