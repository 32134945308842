.subNav {
  height: 61px;
  background: var(--theme-color-primary);
  display: flex;
  position: relative;
  border-bottom: 1px solid var(--theme-color-lightGray2);
}

.subNav.float {
  position: absolute;
  top: 46px;
  width: 100%;
  left: 0;
  background: transparent;
  z-index: 10;
  border-bottom: none; 
  border-top: none; 
  @media (min-width: 1024px) {
    top: 53px;
  }
}

.subConstrain {
  color: var(--theme-color-white);
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  overflow-y: visible;
  position: relative;
}

.subNavLinkContainer {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  transform: translateY(72px);
  min-height: 200px;
  min-width: 340px;
  z-index: 100;
  cursor: pointer;
  border: 1px solid var(--theme-color-lightGray2);
  border-radius: var(--theme-borderRadius);
  overflow: hidden;
}

.clickContainer {
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.noPointerEvents {
  pointer-events: none;
}

.menuLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &.hide {
    display: none;
  }
  > div {
    max-width: 48px;
    max-height: 48px;
  }
}

.menuBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-weight: 700;
  padding: 1rem 2rem;
  background: var(--theme-color-primaryHover);
  border: none;
  border-top: 1px solid var(--theme-color-lightGray2);
  cursor: pointer;
  transition: 0.8s;
  z-index: 101;
  position: absolute;
  top: -1px;
  right: 0;

  &:hover,
  &:active {
    color: var(--theme-color-primaryHover);
    background: var(--theme-color-white);
  }
}

.headerLink {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: var(--theme-spacing-12) var(--theme-spacing-24);
  transition: 0.3s;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--theme-color-lightGray2);
  }

  &:hover,
  &:active {
    color: var(--theme-color-primary);
    box-shadow: inset 0 0 4px var(--theme-color-lightGray2);
    text-decoration: underline;
    transition: 0.3s;
  }

  a {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
  }
}

.headerLinkImage {
  height: 20px;
  width: 20px;
}

.iconLink {
  span {
    line-height: normal;
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

.iconLinkContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  a {
    &:hover,
    &:active {
      color: white;
    }
  }
}

.menuText {
  @media (max-width: 768px) {
    display: none;
  }
}

.header {
  background: linear-gradient(
    to bottom,
    var(--theme-color-primary) 55%,
    white 10%
  );
  color: var(--theme-color-white);
  text-align: center;
  margin-bottom: 2rem;
  h1 {
    margin: 0;
  }
}

.extraPadding {
  padding-bottom: 180px;
}

.pageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: left;
  padding: 1rem 0;

  @media (--theme-mediaQuery-desktop) {
    padding: 2rem 0;
  }
}

.pageTitleIcon {
  width: 64px;
  height: auto;
}

.pageTitleContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.titleImg {
  border-radius: var(--theme-borderRadius);
  overflow: hidden;
}

.page {
  text-align: center;
}

.footer {
  display: grid;
  grid-gap: 2rem;
  background: var(--theme-color-primary);
  color: var(--theme-color-white);
  padding: 2rem;
  text-align: center;
  margin-bottom: -4rem;
}

.logoImage {
  img {
    max-width: 200px;
    margin: 0 auto;
  }
}

.moreGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 2rem;

  @media (--theme-mediaQuery-desktop) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.gridItem {
  display: grid;
  grid-template-columns: 80px 1fr;
  text-align: left;
  grid-gap: 1rem;
  border-radius: var(--theme-borderRadius);
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border: 2px solid rgba(255, 255, 255, 0.2);

  transition: 0.3s;
  transform: scale(1);

  &:hover,
  &:active {
    transform: scale(0.95);
  }
}

.gridThird {
  grid-template-columns: 1fr;
  text-align: center;

  @media (--theme-mediaQuery-desktop) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
}

.double {
  grid-template-columns: 80px 1fr;

  @media (--theme-mediaQuery-desktop) {
    grid-column: 2 / 4;
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      width: 350px;
    }
  }
}

.linkGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem 3rem;
  text-align: left;

  @media (--theme-mediaQuery-desktop) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  a {
    color: white;
  }
}

.emphasisGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.mobileFlex {
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > div {
      max-width: 300px;
    }
  }
}
