.animationContainer {
    height: 108vw;
    max-height: unset;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
    background: var(--theme-color-primary);
    overflow: hidden;
    z-index: 5;
    @media(min-width: 1025px) {
      height: 31.25vw;
    }

}
.available p:empty {
  display: none;
}
.available h2 {
  font-size: 32px; 
  margin-bottom: 0;
  strong {
    font-size: 48px;
  }
  @media (max-width: 768px) {
    padding-bottom: 1rem;
  }
}

.videoContain {
  overflow: hidden;
  border-radius: var(--theme-borderRadius);
  margin-bottom: 2rem;
  >div {
    display: flex;
  }
}

.whiteLink {
  a {
    color: var(--theme-color-white);
  }
}

.whatsNew {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  h1 strong,
  h2 strong {
    font-size: 36px;
    margin-bottom: 0;
    @media(min-width: 786px) {
      font-size: 64px;
    }
  }
  h3 {
    font-size: 28px;
    margin-bottom: 0;
    @media(min-width: 786px) {
      font-size: 40px;
    }
  }

  p {
    text-align: center;
  }

  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    p {
      text-align: left;
    }
  }
}

.whatsNewTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p:empty {
    display: none;
  }
}

.headerContainer {
    display: grid;
    grid-template-columns: 1fr;
    /* grid-gap: 2rem; */
    background: var(--theme-color-primary);
    color: var(--theme-color-white);
    padding-bottom: 2rem;
    text-align: center;
    overflow: hidden;
}

.subHeader {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: -2rem;
    @media(min-width:768px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      margin-top: 0;
      margin-bottom: 4rem;
    }
}

.subHeader .release {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.playerContainer {
    position: relative;
    padding: 2rem 0 2rem 0;
}

.playerContainer .float {
    overflow: hidden;
    position: absolute;
    width: 105%;
    max-height: 240px;
    left: -2%;
    top: 75%;
    transform: translate(0, 10%);
    z-index: 1;
    @media(min-width: 768px) {
      left: -5%;
      width: 120%;
    }
}

.youtube {
  width: 85vw;
  max-width: 1000px;
}

.btnLarge {
  display: flex;
  height: 80px;
  @media (min-width: 768px) {
    height: 100%;
  }
}

.legal {
    font-size: var(--theme-font-size-legalDesktop);
    margin-bottom: 2rem;
}

.desktopOnly {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.hide {
    display: none;
}
