.modesContainer {
    text-align: center;
    margin: 2rem 0;
    overflow: hidden;
}

.modes {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 2rem;
    align-items: stretch;
    text-align: left;
    margin-bottom: 2rem;
    h3 {
      display: flex;
      margin-bottom: 0;
      font-size: 28px;
      margin: 0;
    }
    p:first-of-type {
      margin-top: 0;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
}

.imgSlider {
  display: flex;
  gap: .2rem;
  padding-right: .2rem;
  @media(min-width: 768px){
    gap: .5rem;
    padding-right: .5rem;
  }
}

.duplicates {
  position: absolute;
  display: flex;
  gap: .2rem;
  height: 100%;
  width: 100%;
  top: 0;
  left: 100%;
  padding-right: .2rem;
  @media(min-width: 768px){
    gap: .5rem;
    padding-right: .5rem;
  }
}

.modes .modesTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 2px solid var(--theme-color-primary);
  h3 {
    font-size: 28px;
    @media(min-width: 768px) {
      font-size: 36px;
    }
  }
}

.imgGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    .mode {
      display: grid;
      grid-template-columns: 1fr;
      padding: 0 1rem;
      grid-gap: 1rem;
    }
    @media (min-width: 768px) {
      .mode {
        padding: 0;
      }
      grid-template-columns: 1fr 1fr 1fr;
    }
}

.grid {
    display: grid;
    grid-gap: 2rem;
    margin: 3rem 0;
}

.accHeading {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 1rem;
    text-align: left;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0;
}

.innerContent {
    display: flex;
    flex-direction: column;
}

.miniBlade {
    background: #f8f8f8;
    border-radius: var(--theme-borderRadius);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    padding: 2rem;
    align-items: center;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
    h3 {
      font-size: 28px;
      margin: 0;
      padding-bottom: 1rem;
      display: inline-block;
      border-bottom: 1px solid var(--theme-color-primary);
    }
    .legal {
      margin-top: .5rem;
      text-align: center;
      font-size: var(--theme-font-size-legalDesktop);
    }
    @media (min-width: 768px) {
      grid-gap: 3rem;
      grid-template-columns: 5fr 1fr;
      text-align: left;
    }
}

.miniImg {
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 50%;
  }
}

.miniBlade .miniContent {
    display: flex;
    flex-direction: column;
}

.modeHr {
  width: 100%;
  height: 1px;
  border-top: #DADADA;
  margin: 2rem 0;
}

.hide {
    display: none;
}