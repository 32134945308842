.promoBlade {
  color: var(--theme-color-darkGray1);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.caption {
  background: var(--theme-color-white);
  padding: 1.5rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  /* --theme-mediaQuery-desktop */
  @media (min-width: 1025px) {
    text-align: left;
    flex-direction: row;  
  }
}

.caption p {
  margin: 0;
}

.titleAndCta {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: left;
  flex-direction: column;
  /* --theme-mediaQuery-desktop */
  @media (min-width: 1025px) {
    flex-direction: row;
  }
}

.lightRating {
  &.lightRating div {
    color: var(--theme-color-white);
    border-color: var(--theme-color-white);
  }
}
.scrimLight {
  background: var(--theme-scrim-light);
}

.scrimDark {
  color: var(--theme-color-white);
  background: var(--theme-scrim-dark);
}
