.textSize {
  font-size: var(--theme-font-size-bodyMobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-bodyDesktop);
  }
}

.textAlign {
  text-align: left;
}

.card {
  gap: var(--theme-spacing-16);
  padding-top: var(--theme-spacing-48);
}

.container {
  gap: var(--theme-spacing-24);
  @media (--theme-mediaQuery-tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.divider {
  margin: 0;
  width: 90%;
  height: 1px;
  place-self: center;
  background-color: var(--theme-color-lightGray2);
}
