.together {
    text-align: center;
}

.imgGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 180px auto 180px;
    gap: 1rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    @media (min-width: 1025px) {
      grid-template-columns: 1fr 5.5fr 1fr;
      grid-template-rows: auto;
    }
}

.grid2 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 0 0 1rem 0;
    @media(min-width: 768px) {
      margin: 3rem auto;
      grid-template-columns: 1fr 1fr;
    }
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 30vh);  

  gap: 1rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    grid-template-rows: repeat(4, 20vh);  
    .gridItem {
      grid-column: span 2;
    }
    .leftHalf, .rightHalf {
      grid-column: 1 / 5;
    }
  }
}

.gridItem, .gridItem img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: var(--theme-borderRadius);
}

.textItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  h2 {
    font-size: 36px;
    @media (min-width: 768px) {
      font-size: 64px;
    }
    margin-bottom: 0;
  }
}

/* Left column - 2 images */
.left1 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    @media (min-width: 1025px) {
      grid-row: 1;
    }
  }
  
  .left2 {
    grid-column: 1 / 2;
    grid-row: 3;
    @media (min-width: 1025px) {
      grid-row: 2 / 3;
    }
  }
  
  .centerVid {
    grid-column: 1 / 3;
    grid-row: 2;
    >div {
      display: flex;
      border-radius: var(--theme-borderRadius);
      overflow: hidden;
    }
    @media (min-width: 1025px) {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
    }
  }
  
  /* Right column - 2 images */
  .right1 {
    grid-row: 1 / 2;
    @media (min-width: 1025px) {
      grid-column: 3 / 4;
    }
  }
  
  .right2 {
    grid-row: 3;
    @media (min-width: 1025px) {
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    }
  }

  .leftHalf {
    grid-column: 1 / 3;
  }

  .rightHalf {
    grid-column: 3 / 5;
  }

  .rightDouble {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
  }

  .leftHalfDouble {
    grid-column: 1 / 3;
    grid-row: 3 / 5;
  }