.container {
    background: radial-gradient(circle,#484848, #484848 5%, black 75%);
    background-position: top;    
    background-attachment: fixed;
    color: #fff;
    text-align: center;
    padding: 2rem;
    height: 100%;
    position: relative;
    z-index: 0;
    p:empty {
      display: none;
    }
    h2 {
      font-size: 28px;
      margin-bottom: 0;
    }
    h3 {
      font-size: 22px;
      margin-bottom: 0;
    }
    @media(min-width: 768px) {
      height: 450vh;
      h2 {
        font-size: 64px;
      }
      h3 {
        font-size: 38px;
      }
    }
}


.textBlock {
  position: sticky;
  max-width: 1200px;
  margin: 0 auto;
  top: 42%;
  /* left: 15%; */
  display: flex;
  text-align: left;
  align-items: center;
  z-index: 5;
  @media (min-width: 768px) {
    top: 35%;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    height: 80vh;
  }
}

.scrim {
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
  width: 100%;
  &.centered {
    display: block;
    text-align: center;
  }
  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 768px) and (max-width: 1400px) {
    border-radius: var(--theme-borderRadius);
    padding: 1rem;
    background: rgba(0, 0, 0, .5);
  }
}

.stickyContainer {
    position: sticky;
    max-width: 800px;
    margin: 0 auto 20vh auto;
    height: 25vh;
    top: 20%;
    z-index: 1;
    /* overflow: hidden; */
    &.stickyDock {
      @media(max-width: 768px) {
        margin-top: 15px;
      }
    }
    @media (min-width: 768px) {
      top: 30%;
      height: 50vh;
    }
}

.dockHeader {
  position: absolute;
  bottom: 110%;
  text-align: center;
  width: 100%;
  @media (min-width: 768px) {
    h2 {
      font-size: 48px;
    }
  }
}

.dock {
    position: relative;
    /* transform-origin: top; */
    bottom: -2rem;
    left: 50%;
    height: 100%;
    transform: translate(-50%, 0);
    width: 70%;
    @media(min-width: 768px) {
      bottom: -5rem;
    }
}

.videoContain {
  max-width: 800px;
  margin: 0 auto;
  height: 100%;
}

.video {
    position: absolute;
    top: -50%;
    left: 0;
    z-index: 1;
    min-width: 50vw;
    @media(min-width: 768px) {
      left: 50%;
    }
}

.kickStand {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
  > div {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 768px) {
      width: 200%;
    }
  }
}

.esrbContain {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  color: #fff;
  a >div div {
    color: #fff;
  }
}
