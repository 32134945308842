.textAlign {
  text-align: left;
}

.textContainer {
  width: 90%;
  max-width: 800px;
  margin: auto;
  h1 {
    font-size: var(--theme-font-size-h1LMobile);
    @media (--theme-mediaQuery-desktop) {
      font-size: var(--theme-font-size-h1LDesktop);
    }
  }
  h3 {
    text-align: left;
    font-size: var(--theme-font-size-h3Mobile);
    @media (--theme-mediaQuery-desktop) {
      font-size: var(--theme-font-size-h3Desktop);
    }
  }
  h4 {
    text-align: left;
    font-size: var(--theme-font-size-h2Mobile);
    @media (--theme-mediaQuery-desktop) {
      font-size: var(--theme-font-size-h2Desktop);
    }
  }
  p {
    text-align: left;
    font-size: var(--theme-font-size-bodyMobile);
    @media (--theme-mediaQuery-desktop) {
      font-size: var(--theme-font-size-bodyDesktop);
    }
  }
  em {
    font-size: var(--theme-font-size-legalDesktop);
    font-style: normal;
  }
}
