.table {
  text-align: left;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  td,
  th {
    vertical-align: top;
    padding: var(--theme-spacing-16);
  }
  td:first-child,
  th:first-child {
    border-right: 1px solid var(--theme-color-lightGray2);
    border-radius: 10px;
  }
  tr:not(:last-child) {
    border-bottom: 1px solid var(--theme-color-lightGray2);
  }
  td {
    width: 80%;
  }
}
.container {
  border-radius: 10px;
  border: 1px solid var(--theme-color-lightGray2);
  width: 100%;
}

.heading {
  padding-top: var(--theme-spacing-16);
  padding-bottom: var(--theme-spacing-16);
  font-size: var(--theme-font-size-bodyMobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-bodyDesktop);
  }
}

.text {
  font-size: var(--theme-font-size-bodyMobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-bodyDesktop);
  }
}
