.generations {
    padding: 4rem 0 0 0;
    display: grid;
    grid-gap: 2rem;
    color: var(--theme-color-white);
    text-align: center;
    background-size: cover;
    background-position: center;
    h3 {
      font-size: 28px;
      @media(min-width: 768px) {
        font-size: 36px;
      }
    }
}

.shadow {
  &p, &h2, &h3 {
    text-shadow: 0 0 5px black;
  }
}

.grid {
    display: grid;
}

.generations .assets {
    display: grid;
    grid-template-columns: 1fr 1.13fr;
    align-items: end;
    grid-gap: 2rem;
}