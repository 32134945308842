.title {
  display: grid;
  justify-self: start;
  align-self: center;
  text-align: left;
  font-size: var(--theme-font-size-h3Mobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-h1SDesktop);
  }
}

.line {
  border-bottom: none;
  border-radius: 4px;
  border: var(--theme-color-primary) 1px solid;
}

.table {
  padding-top: var(--theme-spacing-64);
}

.tableHeaderContainer {
  display: flex;
  gap: var(--theme-spacing-12);
  width: 100%;
  justify-content: space-between;
}

.image {
  margin-bottom: var(--theme-spacing-16);
  max-width: 200px;
  @media (--theme-mediaQuery-tablet) {
    max-width: 230px;
  }
}

.farm {
  padding-top: var(--theme-spacing-48);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--theme-spacing-16);
  padding: var(--theme-spacing-48) var(--theme-spacing-48)
    var(--theme-spacing-0);
  span {
    border-radius: var(--theme-spacing-32);
  }
  @media (--theme-mediaQuery-tablet) {
    padding-top: var(--theme-spacing-64);
    gap: var(--theme-spacing-32);
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  background: var(--theme-color-primary);
  color: var(--theme-color-white);
}

.headerText {
  font-size: var(--theme-font-size-h1SMobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-h1SDesktop);
  }
}

.pills {
  font-size: var(--theme-font-size-h3Mobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-h3Desktop);
  }
}
