.card {
  border-radius: var(--theme-borderRadiusSmall);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  max-width: 1143px;
  place-self: center;
  padding-left: var(--theme-spacing-24);
  padding-right: var(--theme-spacing-24);
  margin: 0 auto;
}

.detail {
  place-self: center;
  font-size: var(--theme-font-size-bodyMobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-bodyDesktop);
  }
  strong {
    font-size: var(--theme-font-size-legalDesktop);
  }
}

.button {
  width: 100%;
  @media (--theme-mediaQuery-tablet) {
    width: fit-content;
  }
  svg {
    margin-left: var(--theme-spacing-12);
  }
}

.header {
  text-align: center;
  font-size: var(--theme-font-size-h1SMobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-h1SDesktop);
  }
}

.innergrid {
  display: grid;
  justify-content: center;
  align-items: center;
  gap: var(--theme-spacing-32);
  padding: var(--theme-spacing-0) var(--theme-spacing-40)
    var(--theme-spacing-40) var(--theme-spacing-40);
  @media (--theme-mediaQuery-tablet) {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
  img {
    border-radius: var(--theme-borderRadiusSmall);
  }
}

.singleColumn {
  @media (--theme-mediaQuery-tablet) {
    grid-template-columns: 1fr;
  }
}

.centeredCta {
  display: flex;
  flex-direction: column;
}