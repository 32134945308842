.socialSection {
  margin-top: 2rem;
  h2 {
    font-size: 36px;
    margin-bottom: 0;
  }
  h3 {
    font-size: 28px;
    margin-bottom: 0;
  }
  @media(min-width: 768px) {
    h2 {
      font-size: 64px;
    }
    h3 {
      font-size: 38px;
    }
  }
}
.container {
    text-align: center;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    padding: 0 0 2rem 0;
}

.animationContainer {
  height: 83vw;
  max-height: unset;
  width: 100%;
  position: relative;
  /* background: var(--theme-color-primary); */
  overflow: hidden;
  @media(min-width: 1025px) {
    height: 31.25vw;
  }

}

.media {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: end;
}

.videoContain {
  height: 100%;
}

.content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    justify-content: center;
    align-items: center;
    text-align: left;
    overflow: hidden;
    @media(min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
}

.accessory {
  text-align: center;
  h2, h3 {
    margin-bottom: 1rem;
  }
  p:empty {
    display: none;
  }
}

.socialImg {
  position: relative;
  max-width: 340px;
  margin: 0 auto 2rem auto;
  right: -2rem;
  @media(max-width: 768px) {
    padding: 0 6rem;
  }
}

.nsoContain {
  padding: 0 2rem;
}

.hide {
  display: none;
}