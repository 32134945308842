.switch {
  display: grid;
  grid-template-columns: 1fr 4.6fr 1fr;
  justify-content: center;
  margin: 0 auto;
}

.console {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: .5rem;
  background-size: 100% 100%;
  background-position: center;
  z-index: 3;
  >div {
    height: 100%;
    width: 100%;
    display: flex;
  }
  @media(min-width: 768px) {
    padding: 1.5rem;
  }
}

.joycon {
  /* aspect-ratio: 575 / 1618; */
  /* padding-bottom: 45%; */
  position: relative;
}

.joycon.right {
  @media (max-width: 768px) {
    left: 7px;
  }}

.joycon.left {
  @media (max-width: 768px) {
    right: 7px;
  }
}

.centerConsole {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.video {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 2px;
  width: 50%;
  margin: 0 auto;
  >div {
    display: flex;
  }
}