.container {
    height: 120vh;
    position: relative;
}

.stickyContainer {
    position: sticky;
    top: 0;
    height: 100vh;
}

.tabletopText {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: var(--theme-borderRadius);
    padding: 2rem;
    text-align: center;
    background: var(--theme-scrim-light);
    height: 90vh;
    position: absolute;
    left: 0;
    bottom: 0;  
    margin: 2rem;
    p:empty {
      display: none;
    }
    h2 {
      font-size: 24px;
      margin: 0;
    }
    @media (min-width: 768px) {
      h2 {
        font-size: 32px;
      }
      left: 15%;
      text-align: left;
      max-width: 600px;
    }
}

.floatBg {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  max-width: 1000px;
  z-index: -1;
}

.hide {
    display: none;
}