.grid {
  padding-top: var(--theme-spacing-36);
  padding-bottom: var(--theme-spacing-36);
  display: grid;
  justify-self: center;
  gap: var(--theme-spacing-4);
  img {
    border-radius: var(--theme-borderRadiusSmall);
  }
  @media (--theme-mediaQuery-desktop) {
    grid-template-columns: 1fr 2fr;
  }
}

.verticalgrid {
  gap: var(--theme-spacing-4);
  display: grid;

  grid-auto-flow: row;
}
