.container {
  position: relative;
  background-size: cover;
  width: 100%;
  z-index: 1;
}

.frame {
  padding: 4px;
  justify-content: center;
  align-items: center;
  background: #000;
  >div {
    display: flex;
  }
  @media (min-width: 768px) {
    padding: 8px;
  }
}

.stand {
  left: 50%;
  top: 100%;
  width: 100%;
  transform: translate(-50%, -1px);
  z-index: 1;
}

.video {
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;
}