.slide {
  font-size: var(--theme-font-size-bodyMobile);
  display: grid;
  gap: var(--theme-spacing-32);
  text-align: center;
  img {
    border-radius: var(--theme-borderRadiusSmall);
  }
  @media (--theme-mediaQuery-desktop) {
    font-size: var(--theme-font-size-bodyDesktop);
  }
}

.carousel {
  padding-bottom: var(--theme-spacing-64);
}
