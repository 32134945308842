.container {
    color: #fff;
    text-align: center;
    position: relative;
    margin: 1rem;
    height: 300vh;
}

.slide {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    width: 100%;
    height: 97vh;
    border-radius: 16px;
    background: var(--theme-color-primary);
    @media (min-width: 768px){
      padding: 2rem;
    }
}

.logo {
    max-width: 400px;
    @media(max-width: 768px){
      padding: 3rem;
    }
}

.textBox {
    height: 100%;
    border-radius: var(--theme-borderRadius);
    padding: 2rem;
    max-height: 1080px;

    p:empty {
      display: none;
    }
    h2 {
      font-size: 26px;
      margin-bottom: .5rem;
    }
    @media (min-width: 768px) {
      text-align: left;
      h2 {
        font-size: 32px;
      }
    }
}

.video {
  margin: 1rem 0;
  border-radius: var(--theme-borderRadius);
  overflow: hidden;
  >div {
    display: flex;
  }
}

.motionVideo {
  width: 250%;
  @media(min-width: 768px) {
    width: 100%;
  }
}

.slide.two {
    align-items: flex-end;
    background-color: #FA7A69;
    .switchAngled {
        position: absolute;
        left: 0;
        top: 3rem;
        width: 200%;
        @media (min-width: 768px) {
          top: unset;
          width: 100%;
        }
    }
    .textBox {
        background: linear-gradient(#AF3b24, #AF3b244d);
        @media (min-width: 768px) {
            max-width: 47%;
        }
    }
}

.slide.three {
    background-color: #48B4DC;
    justify-content: flex-end;
    @media (min-width: 768px) {
      align-items: flex-start;
    }
    .slide3Grid {
      display: grid;
      grid-template-columns: 1fr;
    }
    .textBox {
        /* height: 75%; */
        background: linear-gradient(#017488, #0174884d);
        @media (min-width: 768px) {
          max-width: 50%;
        }
    }
}

.joyconHand {
    position: absolute;
    top: 0;
    left: -30%;
    width: 130%;
    height: 100%;
    @media (min-width: 768px) {
      top: -15%;
      left: 0;
      width: 100%;
    }
}