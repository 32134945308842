.overlined {
  border-top: 4px solid var(--theme-color-primary);
  place-self: center;
  border-radius: 3px 3px;
  min-width: 64px;
  width: 80%;
  background: var(--theme-color-primary);
  margin: 0 auto;
}

.titleContainer {
  width: fit-content;
  place-self: center;
  margin: 0 auto;
}

.red {
  color: var(--theme-color-primary);
  font-weight: bold;
  text-align: center;
}

.gray {
  font-weight: bold;
  text-align: center;
  font-size: var(--theme-font-size-h1LMobile);
  @media (--theme-mediaQuery-desktop) {
    font-size: var(--theme-font-size-h1LDesktop);
  }
}
