.subHead {
  background: var(--theme-color-primary);
  color: var(--theme-color-white);
  font-weight: 700;
  display: inline-flex;
  padding: .5rem;
  border-radius: 4px;
}

.titleContain{
  padding: 2rem 2rem 0 2rem;
  text-align: center;
  @media(min-width: 768px) {
    text-align: left;
  }
  p:empty {
    display: none;
  }
  h2, h3 {
    line-height: 1;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 28px;
  }
  @media(min-width: 768px) {
    h2 {
      font-size: 48px;
    }
    h3 {
      font-size: 38px;
    }
  }
}

.childContain {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(min-width: 768px) {
    padding: 2rem;
  }
}