.circle {
  display: grid;
  position: absolute;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: lightblue;
  justify-content: center;
  align-items: center;
  font-size: var(--theme-font-size-captionMobile);
  font-weight: bold;
  padding: var(--theme-spacing-12);
  background: var(--theme-color-primary);
  color: var(--theme-color-white);
  transform: translateX(-50%);
  right: -50%;
  left: 50%;
  top: -77.48px;
  font-size: 10px;
  @media (--theme-mediaQuery-tablet) {
    left: -30px;
    top: -36px;
    transform: none;
    width: 115px;
    height: 115px;
    font-size: var(--theme-spacing-12);
  }
}

.cardContainer {
  margin: auto;
  display: grid;
  max-width: 800px;
  border-radius: var(--border-radius);
  height: fit-content;
  overflow: hidden;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  background: var(--theme-color-white);
}

.noMinWidth {
  min-width: unset;
}

.detailContainer {
  border-radius: var(--border-radius);
  display: grid;
  max-width: 600px;
  margin: auto;
  justify-content: center;
  align-items: center;
  position: relative;
  background: var(--theme-color-lightGray4);
  padding: var(--theme-spacing-32) var(--theme-spacing-32)
    var(--theme-spacing-32) var(--theme-spacing-32);
  font-size: var(--theme-font-size-bodyMobile);
  @media (--theme-mediaQuery-tablet) {
    padding: var(--theme-spacing-32);
    font-size: var(--theme-font-size-bodyDesktop);
  }
}

.logoContainer {
  padding: var(--theme-spacing-8);
  background: var(--theme-color-primary);
  display: grid;
  justify-content: center;
  align-items: center;
}

.text {
  text-align: center;
  p {
    max-width: 100%;
    margin: auto;
    font-weight: bold;
    padding-bottom: var(--theme-spacing-24);
  }
  strong {
    color: var(--theme-color-primary);
  }
  padding: var(--theme-spacing-24);
  @media (--theme-mediaQuery-tablet) {
    padding: var(--theme-spacing-32);
  }
}

.accentText {
  font-weight: bold;
  color: var(--theme-color-primary);
}

.subheading {
  font-weight: bold;
  font-size: var(--theme-font-size-captionDesktop);
}

.body {
  padding: none;
  @media (--theme-mediaQuery-tablet) {
    padding: var(--theme-spacing-12) var(--theme-spacing-48);
  }
}

.innerWrap {
  padding-top: var(--theme-spacing-28);
  @media (--theme-mediaQuery-tablet) {
    padding-top: var(--theme-spacing-0);
  }
}

.padding {
  @media (--theme-mediaQuery-tablet) {
    padding: var(--theme-spacing-0) var(--theme-spacing-36);
  }
}
