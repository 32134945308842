.card {
  position: relative;
  border-radius: var(--theme-borderRadiusSmall);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: var(--theme-spacing-48) var(--theme-spacing-0);
  @media (--theme-mediaQuery-tablet) {
    padding: var(--theme-spacing-48) var(--theme-spacing-64);
  }
}

.rounded {
  border-top: 2px solid var(--theme-color-primary);
  border-bottom: 0px;
  width: 70%;
  border-radius: 5px;
  margin: 0;
  @media (--theme-mediaQuery-tablet) {
    width: 40%;
  }
}

.text {
  font-size: var(--theme-font-size-bodyMobile);
  width: 100%;
  @media (--theme-mediaQuery-tablet) {
    width: 70%;
    font-size: var(--theme-font-size-bodyDesktop);
  }
}

.subheading {
  font-size: var(--theme-font-size-h4Mobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-h4Desktop);
  }
}

.label {
  font-weight: bold;
  padding-top: var(--theme-spacing-24);
  color: var(--theme-color-primary);
  font-size: var(--theme-font-size-h3Mobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-h3Desktop);
    padding-top: var(--theme-spacing-0);
  }
}

.button {
  width: 100%;
  font-size: var(--theme-font-size-bodyMobile);
  @media (--theme-mediaQuery-tablet) {
    width: fit-content;
    font-size: var(--theme-font-size-bodyDesktop);
  }
  svg {
    margin-left: var(--theme-spacing-12);
  }
}

.comingSoon {
  font-size: var(--theme-font-size-titleMobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-titleDesktop);
  }
}

.heading {
  margin-bottom: var(--theme-spacing-16);
}

.padding {
  padding: var(--theme-spacing-0) var(--theme-spacing-32);
  @media (--theme-mediaQuery-tablet) {
    padding: var(--theme-spacing-0);
  }
}

.cameraDesktop {
  display: none;
  @media (--theme-mediaQuery-tablet) {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    height: 120%;
    width: auto;
    right: 0%;
    bottom: -10%;
    z-index: 10;
    img {
      width: auto;
    }
  }
}

.cameraMobile {
  height: 500px;
  position: relative;
  @media (--theme-mediaQuery-tablet) {
    display: none;
    opacity: 0%;
  }
}

.cameraMobileImage {
  width: auto;
  top: -20%;
  right: 0%;
  position: absolute;
  img {
    max-height: 600px;
    width: auto;
  }
}
