.subheading {
  color: var(--theme-color-primary);
  font-weight: bold;
  font-size: var(--theme-font-size-h3Mobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-h3Desktop);
  }
}

.heading {
  font-size: var(--theme-font-size-h1SMobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-h1SDesktop);
  }
}

.textSize {
  font-size: var(--theme-font-size-bodyMobile);
  @media (--theme-mediaQuery-tablet) {
    font-size: var(--theme-font-size-bodyDesktop);
  }
}

.background {
  background-color: var(--theme-color-lightGray4);
  padding: var(--theme-spacing-24) var(--theme-spacing-12);
  @media (--theme-mediaQuery-tablet) {
    padding: var(--theme-spacing-64) var(--theme-spacing-24);
  }
}

.divider {
  width: 80%;
  border: 1px solid var(--theme-color-lightGray2);
}

.item {
  padding-top: var(--theme-spacing-24);
  padding-bottom: var(--theme-spacing-24);
}

.line {
  border-top: 4px solid var(--theme-color-primary);
  place-self: center;
  border-radius: 3px 3px;
  width: 64px;
  background: var(--theme-color-primary);
  margin: 0 auto;
}

.button {
  width: 100%;
  @media (--theme-mediaQuery-tablet) {
    width: fit-content;
  }
  svg {
    margin-left: var(--theme-spacing-12);
  }
}
